import * as React from 'react';
import Layout, { Section } from '../components/layout';
import SEO from '../components/seo';
import { Box, Typography } from '@mui/material';
import { Link } from 'gatsby-theme-material-ui';

const PrivacyPolicyPage: React.FC = () => (
  <Layout gradientHeight={[240, 200, 200]}>
    <SEO title="Privacy Policy" />
    <Section height={[240, 200]} background={'uncommonGradient2'} skewedBottom>
      <Box pt={[10, 8]} color="white">
        <Typography variant="h1" color="inherit">
          Privacy Policy
        </Typography>
      </Box>
    </Section>
    <Section py={2}>
      <h1>Privacy Policy</h1>
      <p>Last updated: 8 August 2024</p>
      <p>
        Your privacy is important to us. It is App Brewing Company Ltd&#39;s
        policy to respect your privacy and comply with any applicable law and
        regulation regarding any personal information we may collect about you,
        including via our app, Kanjiverse, and its associated services.
      </p>
      <p>
        Personal information is any information about you which can be used to
        identify you. This includes information about you as a person (such as
        name, address, and date of birth), your devices, payment details, and
        even information about how you use an app or online service.
      </p>
      <p>
        In the event our app contains links to third-party sites and services,
        please be aware that those sites and services have their own privacy
        policies. After following a link to any third-party content, you should
        read their posted privacy policy information about how they collect and
        use personal information. This Privacy Policy does not apply to any of
        your activities after you leave our app.
      </p>
      <p>This policy is effective as of 8 August 2024.</p>
      <h2>Information We Collect</h2>
      <p>
        Information we collect falls into one of two categories:
        &ldquo;voluntarily provided&rdquo; information and &ldquo;automatically
        collected&rdquo; information.
      </p>
      <p>
        &ldquo;Voluntarily provided&rdquo; information refers to any information
        you knowingly and actively provide us when using our app and its
        associated services.
      </p>
      <p>
        &ldquo;Automatically collected&rdquo; information refers to any
        information automatically sent by your device in the course of accessing
        our app and its associated services.
      </p>
      <h3>Log Data</h3>
      <p>
        When you access our servers via our app, we may automatically log the
        standard data provided by your device. It may include your device's
        Internet Protocol (IP) address, your device type and version, your
        activity within the app, time and date, and other details about your
        usage.
      </p>
      <p>
        Additionally, when you encounter certain errors while using the app, we
        automatically collect data about the error and the circumstances
        surrounding its occurrence. This data may include technical details
        about your device, what you were trying to do when the error happened,
        and other technical information relating to the problem. You may or may
        not receive notice of such errors, even in the moment they occur, that
        they have occurred, or what the nature of the error is.
      </p>
      <p>
        Please be aware that while this information may not be personally
        identifying by itself, it may be possible to combine it with other data
        to personally identify individual persons.
      </p>
      <h3>Device Data</h3>
      <p>
        Our app may access and collect data via your device's in-built tools,
        such as:
      </p>
      <h4>Examples of stored device information collected by Crashlytics</h4>
      <ul>
        <li>An RFC-4122 UUID which permits us to deduplicate crashes</li>
        <li>The timestamp of when the crash occurred</li>
        <li>The app's bundle identifier and full version number</li>
        <li>The device's operating system name and version number</li>
        <li>A boolean indicating whether the device was jailbroken/rooted</li>
        <li>
          The device's model name, CPU architecture, amount of RAM and disk
          space
        </li>
        <li>
          The uint64 instruction pointer of every frame of every currently
          running thread
        </li>
        <li>
          If available in the runtime, the plain-text method or function name
          containing each instruction pointer.
        </li>
        <li>
          If an exception was thrown, the plain-text class name and message
          value of the exception
        </li>
        <li>If a fatal signal was raised, its name and integer code</li>
        <li>
          For each binary image loaded into the application, its name, UUID,
          byte size, and the uint64 base address at which it was loaded into RAM
        </li>
        <li>
          A boolean indicating whether or not the app was in the background at
          the time it crashed
        </li>
        <li>
          An integer value indicating the rotation of the screen at the time of
          crash
        </li>
        <li>
          A boolean indicating whether the device's proximity sensor was
          triggered
        </li>
      </ul>
      <h4>Examples of information collected by Performance Monitoring</h4>
      <ul>
        <li>General device information, such as model, OS, and orientation</li>
        <li>RAM and disk size</li>
        <li>CPU usage</li>
        <li>Carrier (based on Mobile Country and Network Code)</li>
        <li>Radio/Network information (for example, WiFi, LTE, 3G)</li>
        <li>Country (based on IP address)</li>
        <li>Locale/language</li>
        <li>App version</li>
        <li>App foreground or background state</li>
        <li>App package name</li>
        <li>Firebase installation IDs</li>
        <li>Duration times for automated traces</li>
        <li>
          Network URLs (not including URL parameters or payload content) and the
          following corresponding information:
        </li>
        <li>Response codes (for example, 403, 200)</li>
        <li>Payload size in bytes</li>
        <li>Response times</li>
      </ul>
      <p>
        When you install the app or use your device’s tools within the app, we
        request permission to access this information. The specific data we
        collect can depend on the individual settings of your device and the
        permissions you grant when you install and use the app.
      </p>
      <h3>Personal Information</h3>
      <p>
        We may ask for personal information — for example, when you submit
        content to us, when you create an account, or when you contact us —
        which may include one or more of the following:
      </p>
      <ul>
        <li>Name</li>
        <li>Email</li>
        <li>Social media profiles</li>
      </ul>
      <h3>User-Generated Content</h3>
      <p>
        We consider &ldquo;user-generated content&rdquo; to be materials (text,
        image and/or video content) voluntarily supplied to us by our users for
        the purpose of publication on our platform, website or re-publishing on
        our social media channels. All user-generated content is associated with
        the account or email address used to submit the materials.
      </p>
      <p>
        Please be aware that any content you submit for the purpose of
        publication will be public after posting (and subsequent review or
        vetting process). Once published, it may be accessible to third parties
        not covered under this privacy policy. Unless explicitly stated
        otherwise, all published content will be automatically licensed under{' '}
        <Link to="https://creativecommons.org/licenses/by-sa/4.0/">
          CC BY-SA 4.0
        </Link>
        .
      </p>
      <p>
        If you wish to keep a content private, ensure that the opt-in control
        (for instance the <i>Share</i> switch or checkbox) is disabled before
        saving. Once a content has been published, the CC BY-SA 4.0 license will
        apply to this content and is irrevocable, even by subsequently marking
        the content as private or deleting your account.
      </p>
      <p>
        Please refer to our{' '}
        <Link to="/tos#user-generated-content">
          Terms of Service - User-Generated Content
        </Link>{' '}
        for more information.
      </p>
      <h3>Legitimate Reasons for Processing Your Personal Information</h3>
      <p>
        We only collect and use your personal information when we have a
        legitimate reason for doing so. In which instance, we only collect
        personal information that is reasonably necessary to provide our
        services to you.
      </p>
      <h3>Collection and Use of Information</h3>
      <p>
        We may collect personal information from you when you do any of the
        following on our website:
      </p>
      <ul>
        <li>Register for an account</li>
        <li>
          Sign up to receive updates from us via email or social media channels
        </li>
        <li>Use a mobile device or web browser to access our content</li>
        <li>
          Contact us via email, social media, or on any similar technologies
        </li>
        <li>When you mention us on social media</li>
      </ul>
      <p>
        We may collect, hold, use, and disclose information for the following
        purposes, and personal information will not be further processed in a
        manner that is incompatible with these purposes:
      </p>
      <ul>
        <li>
          to provide you with our app and platform's core features and services
        </li>
        <li>
          to enable you to customize or personalize your experience of our app
          and website
        </li>
        <li>to contact and communicate with you</li>
        <li>
          for analytics, market research, and business development, including to
          operate and improve our app, associated applications, and associated
          social media platforms
        </li>
        <li>
          to enable you to access and use our app, associated platforms, and
          associated social media channels
        </li>
        <li>
          to attribute any content (e.g. posts, comments, translations,
          vocabulary lists, etc.) you submit that we publish on our app and
          website
        </li>
        <li>
          for technical assessment, including to operate and improve our app,
          associated applications, and associated social media platforms
        </li>
      </ul>
      <p>
        We may combine voluntarily provided and automatically collected personal
        information with general information or research data we receive from
        other trusted sources. For example, If you consent to us accessing your
        social media profiles, we may combine information sourced from those
        profiles with information received from you directly to provide you with
        an enhanced experience of our app and services.
      </p>
      <h3>Security of Your Personal Information</h3>
      <p>
        When we collect and process personal information, and while we retain
        this information, we will protect it within commercially acceptable
        means to prevent loss and theft, as well as unauthorized access,
        disclosure, copying, use, or modification.
      </p>
      <p>
        Although we will do our best to protect the personal information you
        provide to us, we advise that no method of electronic transmission or
        storage is 100% secure, and no one can guarantee absolute data security.
      </p>
      <p>
        You are responsible for selecting any password and its overall security
        strength, ensuring the security of your own information within the
        bounds of our services. For example, ensuring any passwords associated
        with accessing your personal information and accounts are secure and
        confidential.
      </p>
      <h3>How Long We Keep Your Personal Information</h3>
      <p>
        We keep your personal information only for as long as we need to. This
        time period may depend on what we are using your information for, in
        accordance with this privacy policy. For example, if you have provided
        us with personal information as part of creating an account with us, we
        may retain this information for the duration your account exists on our
        system. If your personal information is no longer required for this
        purpose, we will delete it or make it anonymous by removing all details
        that identify you.
      </p>
      <p>
        However, if necessary, we may retain your personal information for our
        compliance with a legal, accounting, or reporting obligation or for
        archiving purposes in the public interest, scientific, or historical
        research purposes or statistical purposes.
      </p>
      <h2>Use of AI and OpenAI Services</h2>
      <p>
        Kanjiverse uses artificial intelligence (AI) to generate sentences and
        other educational content tailored to your learning experience. The AI
        models help us create personalized content that aligns with your
        learning goals and interests.
      </p>
      <p>
        We use services provided by OpenAI to generate some of this content.
        This means that certain data, such as your queries or prompts, may be
        sent to OpenAI's servers for processing. OpenAI does not store personal
        information, but the data used for generating content might be
        temporarily stored during processing.
      </p>
      <p>
        We ensure that any data sent to third-party services, including OpenAI,
        is encrypted and handled securely. We do not share your personal
        information with these services unless it is necessary to provide you
        with the AI-generated content.
      </p>
      <h2>Children’s Privacy</h2>
      <p>
        We do not aim any of our products or services directly at children under
        the age of 13, and we do not knowingly collect personal information
        about children under 13.
      </p>
      <h2>Disclosure of Personal Information to Third Parties</h2>
      <p>We may disclose personal information to:</p>
      <ul>
        <li>a parent, subsidiary, or affiliate of our company</li>
        <li>
          third-party service providers for the purpose of enabling them to
          provide their services, including (without limitation) IT service
          providers, data storage, hosting and server providers, ad networks,
          analytics, error loggers, debt collectors, maintenance or
          problem-solving providers, marketing or advertising providers,
          professional advisors, and payment systems operators
        </li>
        <li>our employees, contractors, and/or related entities</li>
        <li>our existing or potential agents or business partners</li>
        <li>
          credit reporting agencies, courts, tribunals, and regulatory
          authorities, in the event you fail to pay for goods or services we
          have provided to you
        </li>
        <li>
          courts, tribunals, regulatory authorities, and law enforcement
          officers, as required by law, in connection with any actual or
          prospective legal proceedings, or in order to establish, exercise, or
          defend our legal rights
        </li>
        <li>
          third parties, including agents or sub-contractors, who assist us in
          providing information, products, services, or direct marketing to you
        </li>
        <li>third parties to collect and process data</li>
        <li>
          an entity that buys, or to which we transfer all or substantially all
          of our assets and business
        </li>
      </ul>
      <h2>Third parties we currently use</h2>
      <p>
        Personal Data is collected for the following purposes and using the
        following services:
      </p>
      <h3 id="google-analytics-google-llc-">
        <strong>Google Analytics (Google LLC)</strong>
      </h3>
      <p>
        Google Analytics is a web analysis service provided by Google LLC
        (“Google”). Google utilizes the Data collected to track and examine the
        use of this Application, to prepare reports on its activities and share
        them with other Google services. Google may use the Data collected to
        contextualize and personalize the ads of its own advertising network.
      </p>
      <p>Personal Data processed: Cookies; Usage Data.</p>
      <p>
        Place of processing: United States –{' '}
        <Link
          to="https://policies.google.com/privacy"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </Link>{' '}
        –{' '}
        <Link to="https://tools.google.com/dlpage/gaoptout?hl=en">Opt Out</Link>
        . Privacy Shield participant.
      </p>
      <h3 id="google-tag-manager-google-llc-">
        <strong>Google Tag Manager (Google LLC)</strong>
      </h3>
      <p>
        Google Tag Manager is a tag management service provided by Google
        LLC.Personal Data processed: Usage Data.
      </p>
      <p>
        Place of processing: United States –{' '}
        <Link
          to="https://policies.google.com/privacy"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </Link>
        . Privacy Shield participant.
      </p>
      <h3 id="firebase">
        <strong>Firebase (Google LLC)</strong>
      </h3>
      <p>
        Firebase is a platform developed by Google for creating mobile and web
        applications. Crashlytics helps collecting, analyzing and organizing app
        crash reports. Firebase Performance Monitoring is a service that helps
        you to gain insight into the performance characteristics of your Apple,
        Android, and web apps. Firebase Remote Config is a cloud service that
        lets you change the behavior and appearance of your app without
        requiring users to download an app update.
      </p>
      <p>
        Personal Data processed: Crash traces; Device specs; IP addresses; Email
        addresses; Name.
      </p>
      <p>
        Place of processing: United State (Authentication), Singapore
        (Crashlytics, Performance Monitoring, Remote Config) –{' '}
        <Link
          to="https://firebase.google.com/support/privacy"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </Link>
      </p>
      <h3 id="digitalocean">
        <strong>DigitalOcean (DigitalOcean LLC)</strong>
      </h3>
      <p>
        DigitalOcean, Inc. is an American cloud infrastructure provider. This is
        where all services and databases of App Brewing Company Ltd are
        self-hosted including the company{' '}
        <Link to="https://appbrew.co">website</Link>, the Kanjiverse{' '}
        <Link to="https://kanjiverse.com">website</Link> and{' '}
        <Link to="https://blog.kanjiverse.com">blog</Link>, the Hatlas database
        and API, the Kanjiverse{' '}
        <Link to="https://app.kanjiverse.com">web app</Link>, and Mautic
        (marketing automation management tool).
      </p>
      <p>
        Personal Data processed: Email addresses; Name; In-App History;
        Settings; User-Generated Content.
      </p>
      <p>
        Place of processing: Singapore –{' '}
        <Link
          to="https://www.digitalocean.com/legal/privacy-policy"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </Link>
        .
      </p>
      <h2>International Transfers of Personal Information</h2>
      <p>
        The personal information we collect is stored and/or processed in
        Singapore, and United States, or where we or our partners, affiliates,
        and third-party providers maintain facilities.
      </p>
      <p>
        The countries to which we store, process, or transfer your personal
        information may not have the same data protection laws as the country in
        which you initially provided the information. If we transfer your
        personal information to third parties in other countries: (i) we will
        perform those transfers in accordance with the requirements of
        applicable law; and (ii) we will protect the transferred personal
        information in accordance with this privacy policy.
      </p>
      <h2>Your Rights and Controlling Your Personal Information</h2>
      <p>
        <strong>Your choice:</strong> By providing personal information to us,
        you understand we will collect, hold, use, and disclose your personal
        information in accordance with this privacy policy. You do not have to
        provide personal information to us, however, if you do not, it may
        affect your use of our app or the products and/or services offered on or
        through it.
      </p>
      <p>
        <strong>Information from third parties:</strong> If we receive personal
        information about you from a third party, we will protect it as set out
        in this privacy policy. If you are a third party providing personal
        information about somebody else, you represent and warrant that you have
        such person’s consent to provide the personal information to us.
      </p>
      <p>
        <strong>Marketing permission:</strong> If you have previously agreed to
        us using your personal information for direct marketing purposes, you
        may change your mind at any time by contacting us using the details
        below.
      </p>
      <p>
        <strong>Access:</strong> You may request details of the personal
        information that we hold about you.
      </p>
      <p>
        <strong>Correction:</strong> If you believe that any information we hold
        about you is inaccurate, out of date, incomplete, irrelevant, or
        misleading, please contact us using the details provided in this privacy
        policy. We will take reasonable steps to correct any information found
        to be inaccurate, incomplete, misleading, or out of date.
      </p>
      <p>
        <strong>Non-discrimination:</strong> We will not discriminate against
        you for exercising any of your rights over your personal information.
        Unless your personal information is required to provide you with a
        particular service or offer (for example serving particular content to
        your device), we will not deny you goods or services and/or charge you
        different prices or rates for goods or services, including through
        granting discounts or other benefits, or imposing penalties, or provide
        you with a different level or quality of goods or services.
      </p>
      <p>
        <strong>Downloading of Personal Information:</strong> We provide a means
        for you to download the personal information you have shared through our
        app in Account {'>'} Profile {'>'} Download Data.
      </p>
      <p>
        <strong>Notification of data breaches:</strong> We will comply with laws
        applicable to us in respect of any data breach.
      </p>
      <p>
        <strong>Complaints:</strong> If you believe that we have breached a
        relevant data protection law and wish to make a complaint, please
        contact us using the details below and provide us with full details of
        the alleged breach. We will promptly investigate your complaint and
        respond to you, in writing, setting out the outcome of our investigation
        and the steps we will take to deal with your complaint. You also have
        the right to contact a regulatory body or data protection authority in
        relation to your complaint.
      </p>
      <p>
        <strong>Unsubscribe:</strong> To unsubscribe from our email database or
        opt-out of communications (including marketing communications), please
        opt-out using the opt-out facilities provided in the communication.
      </p>
      <h2>Use of Cookies</h2>
      <p>
        Our privacy policy covers the use of cookies between your device and our
        servers. A cookie is a small piece of data that an app may store on your
        device, typically containing a unique identifier that allows the app
        servers to recognise your device when you use the app; information about
        your account, session and/or device; additional data that serves the
        purpose of the cookie, and any self-maintenance information about the
        cookie itself.
      </p>
      <p>
        We use cookies to give your device access to core features of our app,
        to track app usage and performance on your device, to tailor your
        experience of our app based on your preferences, and to serve
        advertising to your device. Any communication of cookie data between
        your device and our servers occurs within a secure environment.
      </p>
      <p>
        Please refer to our <Link to="#cookie-policy">Cookie Policy</Link> for
        more information.
      </p>
      <h2>Business Transfers</h2>
      <p>
        If we or our assets are acquired, or in the unlikely event that we go
        out of business or enter bankruptcy, we would include data, including
        your personal information, among the assets transferred to any parties
        who acquire us. You acknowledge that such transfers may occur, and that
        any parties who acquire us may, to the extent permitted by applicable
        law, continue to use your personal information according to this policy,
        which they will be required to assume as it is the basis for any
        ownership or use rights we have over such information.
      </p>
      <h2>Limits of Our Policy</h2>
      <p>
        Our app may link to external sites that are not operated by us. Please
        be aware that we have no control over the content and policies of those
        sites, and cannot accept responsibility or liability for their
        respective privacy practices.
      </p>
      <h2>Changes to This Policy</h2>
      <p>
        At our discretion, we may change our privacy policy to reflect updates
        to our business processes, current acceptable practices, or legislative
        or regulatory changes. If we decide to change this privacy policy, we
        will post the changes here and on our website.
      </p>
      <p>
        If the changes are significant, or if required by applicable law, we
        will contact you (based on your selected preferences for communications
        from us) and all our registered users with the new details and links to
        the updated or changed policy.
      </p>
      <p>
        If required by law, we will get your permission or give you the
        opportunity to opt in to or opt out of, as applicable, any new uses of
        your personal information.
      </p>
      <h2>Additional Disclosures for Australian Privacy Act Compliance (AU)</h2>
      <h3>International Transfers of Personal Information</h3>
      <p>
        Where the disclosure of your personal information is solely subject to
        Australian privacy laws, you acknowledge that some third parties may not
        be regulated by the Privacy Act and the Australian Privacy Principles in
        the Privacy Act. You acknowledge that if any such third party engages in
        any act or practice that contravenes the Australian Privacy Principles,
        it would not be accountable under the Privacy Act, and you will not be
        able to seek redress under the Privacy Act.
      </p>
      <h2>
        Additional Disclosures for General Data Protection Regulation (GDPR)
        Compliance (EU)
      </h2>
      <h3>Data Controller / Data Processor</h3>
      <p>
        The GDPR distinguishes between organizations that process personal
        information for their own purposes (known as &ldquo;data
        controllers&rdquo;) and organizations that process personal information
        on behalf of other organizations (known as &ldquo;data
        processors&rdquo;). We, App Brewing Company Ltd, located at the address
        provided in our Contact Us section, are a Data Controller with respect
        to the personal information you provide to us.
      </p>
      <h3>Legal Bases for Processing Your Personal Information</h3>
      <p>
        We will only collect and use your personal information when we have a
        legal right to do so. In which case, we will collect and use your
        personal information lawfully, fairly, and in a transparent manner. If
        we seek your consent to process your personal information, and you are
        under 16 years of age, we will seek your parent or legal guardian’s
        consent to process your personal information for that specific purpose.
      </p>
      <p>
        Our lawful bases depend on the services you use and how you use them.
        This means we only collect and use your information on the following
        grounds:
      </p>
      <h4>Consent From You</h4>
      <p>
        Where you give us consent to collect and use your personal information
        for a specific purpose. You may withdraw your consent at any time using
        the facilities we provide; however this will not affect any use of your
        information that has already taken place. You may consent to providing
        your email address for the purpose of receiving marketing emails from
        us. While you may unsubscribe at any time, we cannot recall any email we
        have already sent. If you have any further enquiries about how to
        withdraw your consent, please feel free to enquire using the details
        provided in the Contact Us section of this privacy policy.
      </p>
      <h4>Performance of a Contract or Transaction</h4>
      <p>
        Where you have entered into a contract or transaction with us, or in
        order to take preparatory steps prior to our entering into a contract or
        transaction with you. For example, we need technical information about
        your device in order to provide the essential features of our app.
      </p>
      <h4>Our Legitimate Interests</h4>
      <p>
        Where we assess it is necessary for our legitimate interests, such as
        for us to provide, operate, improve and communicate our services. For
        example, we collect technical information about your device in order to
        improve and personalize your experience of our app. We consider our
        legitimate interests to include research and development, understanding
        our audience, marketing and promoting our services, measures taken to
        operate our services efficiently, marketing analysis, and measures taken
        to protect our legal rights and interests.
      </p>
      <h4>Compliance with Law</h4>
      <p>
        In some cases, we may have a legal obligation to use or keep your
        personal information. Such cases may include (but are not limited to)
        court orders, criminal investigations, government requests, and
        regulatory obligations. If you have any further enquiries about how we
        retain personal information in order to comply with the law, please feel
        free to enquire using the details provided in the Contact Us section of
        this privacy policy.
      </p>
      <h3>
        International Transfers Outside of the European Economic Area (EEA)
      </h3>
      <p>
        We will ensure that any transfer of personal information from countries
        in the European Economic Area (EEA) to countries outside the EEA will be
        protected by appropriate safeguards, for example by using standard data
        protection clauses approved by the European Commission, or the use of
        binding corporate rules or other legally accepted means.
      </p>
      <h3>Your Rights and Controlling Your Personal Information</h3>
      <p>
        <strong>Restrict:</strong> You have the right to request that we
        restrict the processing of your personal information if (i) you are
        concerned about the accuracy of your personal information; (ii) you
        believe your personal information has been unlawfully processed; (iii)
        you need us to maintain the personal information solely for the purpose
        of a legal claim; or (iv) we are in the process of considering your
        objection in relation to processing on the basis of legitimate
        interests.
      </p>
      <p>
        <strong>Objecting to processing:</strong> You have the right to object
        to processing of your personal information that is based on our
        legitimate interests or public interest. If this is done, we must
        provide compelling legitimate grounds for the processing which overrides
        your interests, rights, and freedoms, in order to proceed with the
        processing of your personal information.
      </p>
      <p>
        <strong>Data portability:</strong> You may have the right to request a
        copy of the personal information we hold about you. Where possible, we
        will provide this information in JSON format or other easily readable
        machine format. You may also have the right to request that we transfer
        this personal information to a third party.
      </p>
      <p>
        <strong>Deletion:</strong> You may have a right to request that we
        delete the personal information we hold about you at any time, and we
        will take reasonable steps to delete your personal information from our
        current records. If you ask us to delete your personal information, we
        will let you know how the deletion affects your use of our app, website
        or products and services. There may be exceptions to this right for
        specific legal reasons which, if applicable, we will set out for you in
        response to your request. If you terminate or delete your account, we
        will delete your personal information within 30 days of the deletion of
        your account. Please be aware that search engines and similar third
        parties may still retain copies of your personal information that has
        been made public at least once, like certain profile information and
        public comments, even after you have deleted the information from our
        services or deactivated your account.
      </p>
      <h2>Additional Disclosures for California Compliance (US)</h2>
      <p>
        Under California Civil Code Section 1798.83, if you live in California
        and your business relationship with us is mainly for personal, family,
        or household purposes, you may ask us about the information we release
        to other organizations for their marketing purposes.
      </p>
      <p>
        To make such a request, please contact us using the details provided in
        this privacy policy with &ldquo;Request for California privacy
        information&rdquo; in the subject line. You may make this type of
        request once every calendar year. We will email you a list of categories
        of personal information we revealed to other organisations for their
        marketing purposes in the last calendar year, along with their names and
        addresses. Not all personal information shared in this way is covered by
        Section 1798.83 of the California Civil Code.
      </p>
      <h3>Do Not Track</h3>
      <p>
        Some browsers have a &ldquo;Do Not Track&rdquo; feature that lets you
        tell websites that you do not want to have your online activities
        tracked. At this time, we do not respond to browser &ldquo;Do Not
        Track&rdquo; signals.
      </p>
      <p>
        We adhere to the standards outlined in this privacy policy, ensuring we
        collect and process personal information lawfully, fairly,
        transparently, and with legitimate, legal reasons for doing so.
      </p>
      <h3>Cookies and Pixels</h3>
      <p>
        At all times, you may decline cookies from our site if your browser
        permits. Most browsers allow you to activate settings on your browser to
        refuse the setting of all or some cookies. Accordingly, your ability to
        limit cookies is based only on your browser’s capabilities. Please refer
        to the Cookies section of this privacy policy for more information.
      </p>
      <h3>CCPA-permitted financial incentives</h3>
      <p>
        In accordance with your right to non-discrimination, we may offer you
        certain financial incentives permitted by the CCPA that can result in
        different prices, rates, or quality levels for the goods or services we
        provide.
      </p>
      <p>
        Any CCPA-permitted financial incentive we offer will reasonably relate
        to the value of your personal information, and we will provide written
        terms that describe clearly the nature of such an offer. Participation
        in a financial incentive program requires your prior opt-in consent,
        which you may revoke at any time.
      </p>
      <h3>California Notice of Collection</h3>
      <p>
        In the past 12 months, we have collected the following categories of
        personal information enumerated in the California Consumer Privacy Act:
      </p>
      <ul>
        <li>
          Identifiers, such as name, email address, IP address, and an ID or
          number assigned to your account.
        </li>
        <li>
          Demographics, such as your age or gender. This category includes data
          that may qualify as protected classifications under other California
          or federal laws.
        </li>
        <li>Internet activity, such as your interactions with our service.</li>
        <li>Geolocation data: country inferred from IP address.</li>
        <li>
          Inferences, such as information about your interests, preferences and
          favorites.
        </li>
      </ul>
      <p>
        For more information on information we collect, including the sources we
        receive information from, review the &ldquo;Information We
        Collect&rdquo; section. We collect and use these categories of personal
        information for the business purposes described in the &ldquo;Collection
        and Use of Information&rdquo; section, including to provide and manage
        our Service.
      </p>
      <h3>Right to Know and Delete</h3>
      <p>
        If you are a California resident, you have rights to delete your
        personal information we collected and know certain information about our
        data practices in the preceding 12 months. In particular, you have the
        right to request the following from us:
      </p>
      <ul>
        <li>
          The categories of personal information we have collected about you;
        </li>
        <li>
          The categories of sources from which the personal information was
          collected;
        </li>
        <li>
          The categories of personal information about you we disclosed for a
          business purpose or sold;
        </li>
        <li>
          The categories of third parties to whom the personal information was
          disclosed for a business purpose or sold;
        </li>
        <li>
          The business or commercial purpose for collecting or selling the
          personal information; and
        </li>
        <li>
          The specific pieces of personal information we have collected about
          you.
        </li>
      </ul>
      <p>
        To exercise any of these rights, please contact us using the details
        provided in this privacy policy.
      </p>
      <h3>Shine the Light</h3>
      <p>
        If you are a California resident, in addition to the rights discussed
        above, you have the right to request information from us regarding the
        manner in which we share certain personal information as defined by
        California’s &ldquo;Shine the Light&rdquo; with third parties and
        affiliates for their own direct marketing purposes.
      </p>
      <p>
        To receive this information, send us a request using the contact details
        provided in this privacy policy. Requests must include &ldquo;California
        Privacy Rights Request&rdquo; in the first line of the description and
        include your name, street address, city, state, and ZIP code.
      </p>
      <h2>Contact Us</h2>
      <p>
        For any questions or concerns regarding your privacy, you may contact us
        at <Link to="mailto:contact@appbrew.co">contact@appbrew.co</Link>
      </p>
      <h1 id="cookie-policy">Cookie Policy</h1>
      <p>
        We use cookies to help improve your experience of our website at{' '}
        <Link to="https://kanjiverse.com">kanjiverse.com</Link>. This cookie
        policy is part of App Brewing Company Ltd&#39;s privacy policy. It
        covers the use of cookies between your device and our site.
      </p>
      <p>
        We also provide basic information on third-party services we may use,
        who may also use cookies as part of their service. This policy does not
        cover their cookies.
      </p>
      <p>
        If you do not wish to accept cookies from us, you should instruct your
        browser to refuse cookies from{' '}
        <Link to="https://kanjiverse.com">kanjiverse.com</Link>. In such a case,
        we may be unable to provide you with some of your desired content and
        services.
      </p>
      <h2>What is a cookie?</h2>
      <p>
        A cookie is a small piece of data that a website stores on your device
        when you visit. It typically contains information about the website
        itself, a unique identifier that allows the site to recognize your web
        browser when you return, additional data that serves the cookie’s
        purpose, and the lifespan of the cookie itself.
      </p>
      <p>
        Cookies are used to enable certain features (e.g. logging in), track
        site usage (e.g. analytics), store your user settings (e.g. time zone,
        notification preferences), and to personalize your content (e.g.
        advertising, language).
      </p>
      <p>
        Cookies set by the website you are visiting are usually referred to as
        first-party cookies. They typically only track your activity on that
        particular site.
      </p>
      <p>
        Cookies set by other sites and companies (i.e. third parties) are called
        third-party cookies They can be used to track you on other websites that
        use the same third-party service.
      </p>
      <h2>Types of cookies and how we use them</h2>
      <h3>Essential cookies</h3>
      <p>
        Essential cookies are crucial to your experience of a website, enabling
        core features like user logins, account management, shopping carts, and
        payment processing.
      </p>
      <p>
        We use essential cookies to enable certain functions on our website.
      </p>
      <h3>Performance cookies</h3>
      <p>
        Performance cookies track how you use a website during your visit.
        Typically, this information is anonymous and aggregated, with
        information tracked across all site users. They help companies
        understand visitor usage patterns, identify and diagnose problems or
        errors their users may encounter, and make better strategic decisions in
        improving their audience’s overall website experience. These cookies may
        be set by the website you’re visiting (first-party) or by third-party
        services. They do not collect personal information about you.
      </p>
      <p>We use performance cookies on our site.</p>
      <h3>Functionality cookies</h3>
      <p>
        Functionality cookies are used to collect information about your device
        and any settings you may configure on the website you’re visiting (like
        language and time zone settings). With this information, websites can
        provide you with customized, enhanced, or optimized content and
        services. These cookies may be set by the website you’re visiting
        (first-party) or by third-party services.
      </p>
      <p>We use functionality cookies for selected features on our site.</p>
      <h3>Targeting/advertising cookies</h3>
      <p>
        Targeting/advertising cookies help determine what promotional content is
        most relevant and appropriate to you and your interests. Websites may
        use them to deliver targeted advertising or limit the number of times
        you see an advertisement. This helps companies improve the effectiveness
        of their campaigns and the quality of content presented to you. These
        cookies may be set by the website you’re visiting (first-party) or by
        third-party services. Targeting/advertising cookies set by third-parties
        may be used to track you on other websites that use the same third-party
        service.
      </p>
      <p>We do not use targeting/advertising cookies on our site.</p>
    </Section>
  </Layout>
);

export default PrivacyPolicyPage;
